import HomePage from '~/pages/HomePage.vue';
import LeaderboardPage from '~/pages/LeaderboardPage.vue';
import ManageMergeRequestPage from '~/pages/ManageMergeRequestPage.vue';
import UserEditPage from '~/pages/UserEditPage.vue';
import UserPage from '~/pages/UserPage.vue';

export default [
  {
    name: 'home',
    path: '/',
    component: HomePage,
  },
  {
    name: 'manage-merge-request',
    path: '/manage-merge-request/:mergeRequestId',
    component: ManageMergeRequestPage,
  },
  {
    name: 'leaderboard',
    path: '/leaderboard',
    component: LeaderboardPage,
  },
  {
    name: 'user',
    path: '/users/:userId',
    component: UserPage,
  },
  {
    name: 'user-edit',
    path: '/users/:userId/edit',
    component: UserEditPage,
  },
];
