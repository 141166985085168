<script setup>
import { ref } from 'vue';
import { BLink, BTable } from 'bootstrap-vue-next';
import { buildUrl } from '~/common/UrlUtils';
import ContributorLevelBadge from '~/common/components/ContributorLevelBadge.vue';
import FilterCard from '~/common/components/FilterCard.vue';
import { numberProps } from '~/common/FieldFormats';

const leaders = ref([]);
const busy = ref(true);
const filterData = ref({
  fromDate: '',
  toDate: '',
  communityOnly: true,
});

const fields = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'contributor_level',
    label: 'Level',
    class: 'text-center',
    tdClass: 'p-0',
  },
  {
    key: 'opened_merge_requests',
    label: 'Open',
    ...numberProps,
  },
  {
    key: 'merged_commits',
    label: 'Commit',
    ...numberProps,
  },
  {
    key: 'merged_merge_requests',
    label: 'Merge',
    ...numberProps,
  },
  {
    key: 'merged_with_issues',
    label: 'Link',
    ...numberProps,
  },
  {
    key: 'opened_issues',
    label: 'Issue',
    ...numberProps,
  },
  {
    key: 'added_notes',
    label: 'Note',
    ...numberProps,
  },
  {
    key: 'bonus_points',
    label: 'Bonus',
    ...numberProps,
  },
  {
    key: 'score',
    label: 'Score',
    ...numberProps,
  },
];

const filter = () => {
  busy.value = true;
  fetchData();
};

const fetchData = async () => {
  const query = {
    from_date: filterData.value.fromDate,
    to_date: filterData.value.toDate,
    community_only: filterData.value.communityOnly,
  };
  const url = buildUrl('/api/v1/leaderboard', { query });

  const response = await fetch(url);
  leaders.value = await response.json();
  busy.value = false;
};
</script>

<template>
  <FilterCard
    v-model="filterData"
    show-community-only
    @filter="filter"
  />

  <BTable
    :fields="fields"
    :items="leaders"
    :busy="busy"
    show-empty
  >
    <template #head(score)="data">
      <a
        href="https://handbook.gitlab.com/handbook/marketing/developer-relations/strategic-plans/contributor-program-unification/#contribution-weights"
        target="_blank"
      >
        {{ data.field.label }}
      </a>
    </template>
    <template #cell(username)="data">
      <BLink
        :to="{
          name: 'user',
          params: { userId: data.item.id },
          query: { fromDate: filterData.fromDate, toDate: filterData.toDate }
        }"
      >
        {{ data.value }}
      </BLink>
    </template>
    <template #cell(contributor_level)="{ value }">
      <ContributorLevelBadge :contributor-level="value" />
    </template>
  </BTable>
</template>
